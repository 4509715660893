// @mixin scroll-style {
//   &::-webkit-scrollbar {
//     /*滚动条整体样式*/
//     width : 6px;  /*高宽分别对应横竖滚动条的尺寸*/
//     height: 0px;
//     }
//   &::-webkit-scrollbar-thumb {
//     /*滚动条里面小方块*/
//     border-radius   : 10px;
//     background-color: #808080;
//     }
//   &::-webkit-scrollbar-track {
//     /*滚动条里面轨道*/
//     box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
//     background   : #404040;
//     border-radius: 10px;
//   }
//   &::-webkit-scrollbar-corner {
//     // display: none;
//     opacity: 0;
//   }
// }
// 增加页面最小高度
// .v-application{
//   min-height: 100vh !important;
// }
.rest-scrollbar {
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
    }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #808080;
    }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #404040;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-corner {
    // display: none;
    opacity: 0;
  }
}
.hidden-scrollbar {
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    display: none !important;
  }
}
.rest-primary-btn{
  &::before{
    background: none;
  }
  &:hover::before{
    opacity: 1 !important;
    background: #FF7E9D !important;
  }
}
.rest-default-btn{
  &::before{
    background: none;
  }
  &:hover::before{
    opacity: 1 !important;
    background: #3C4254 !important;
  }
}
.v-input__control{
  .v-input__slot{
    // background: #1E1F25 !important;
    min-height: unset !important;
    & fieldset{
      border: 1px solid #3C4254;
    }
    .v-text-field__slot .v-label{
      top: 8px !important;
      color: #757B8C;
      font-size: 14px;
    }
  }
}
.v-application{
  font-family: Helvetica,Impact !important;
  line-height: normal;
  font-size: 14px;
  .v-application--wrap p {
    margin-bottom: 0px;
  }
}
.v-pagination__item{
  height: 28px !important;
  min-width: 28px !important;
}
.v-data-table__wrapper{
  // @include scroll-style;
  background-color: #1e1f25;
  .v-data-table-header tr th{
    background: #1e1f25 !important;
  }
  table .text-start{
    white-space: nowrap;
    height: 100%;
  }
  table tbody tr td{
    font-size: 16px !important;
  }
  table tbody tr:hover{
    background: rgba(47, 50, 65, 0.5) !important;
  }
}
.v-menu__content{
  background: rgba(60, 66, 84, .2) !important;
  backdrop-filter: blur(14px);
  border: 1px solid #3C4254;
  padding: 0px !important;
  min-width: 0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, .5) !important;
  // @include scroll-style;
}
.v-btn {
  letter-spacing: 0 !important;
  min-width: 0 !important;
}

.v-application .v-progress-circular.v-progress-circular {
 color: #757B8C !important;
}

.v-application .v-progress-linear__background.primary {
  background-color: #757B8C !important;
  border-color: #757B8C !important;
 }

.v-application .v-progress-linear__indeterminate.primary {
  background: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%) !important;
  // border-color: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%) !important;
}

.v-tooltip__content {
  padding: 10px 18px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Helvetica-Bold,Helvetica;
  font-weight: 700;
  color: #fff;
  background: rgba(60,66,84,.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .5);
  border: 1px solid #3c4254;
  backdrop-filter: blur(12px);
  border-radius: 6px;
}



// 重置mui
// 重置menu菜单
.reset-mui-popover-menu{
  .MuiMenu-paper{
    background: rgba(60,66,84,.2)!important;
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    border: 1px solid #3c4254;
    padding: 0!important;
    min-width: 0!important;
    border-radius: 10px!important;
    -webkit-box-shadow: 0 4px 10px 0 rgba(0,0,0,.5)!important;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.5)!important;
    margin-top: 6px;
    >ul{
      padding: 0 !important;
    }
  }
}
// 重置loading进度条
.reset-linearProgress{
  background-color: rgba(117, 123, 140, .3) !important;
  position: absolute !important;
  width: 100%;
  span{
    background: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%) !important;
  }
}

// 重置select选中
.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #fff !important;
  border-width: 1px !important;
}

// 重置checkbox
.MuiCheckbox-root{
  &.Mui-checked{
    color: #fa2256 !important;
    caret-color: #fa2256 !important;
  }
}

// 重置 Button
.MuiButton-root{
  text-transform: initial !important;
}

// 重置button
.reset-default-black-button{
  // color: #fff !important;
  // background: #282C38 !important;
  &:hover{
    background: #3c4254!important;
  }
}

// 重置table
.reset-mui-table {
  >div{
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
      }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background-color: #808080;
      }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #404040;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-corner {
      // display: none;
      opacity: 0;
    }
  }
  .MuiPaper-root{
    border: none !important;
    background-color: #1e1f25 !important;
    border-radius: 4px !important;
    flex: 1;
  }
  .MuiTable-root{
    height: unset !important;
  }
  .MuiTableCell-root{
    border-color: #41424C;
    background-color: #1e1f25 !important;
  }
  .MuiTableHead-root{
    .MuiTableRow-root{
      height: 48px;
      white-space: nowrap;
      &:hover{
        background-color: unset !important;
      }
    }
    .MuiTableCell-root{
      padding: 0 16px;
    }
  }
  .MuiTableBody-root .MuiTableRow-root {
    &:hover, &.selected{
      .MuiTableCell-root{
        background-color: rgba(47,50,65,.5) !important;
      }
    }
  }
}

// 重置分页
.reset-pagination {
  .MuiPaginationItem-root {
    width: 28px !important;
    height: 28px !important;
    margin: 0 3px !important;
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: #FA2256 !important;
  }
}

// 重置提示
.MuiTooltip-popper {
  >div{
    background: rgba(60,66,84,.2)!important;
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    border: 1px solid #3c4254;
    padding: 12px 18px !important;
    min-width: 0!important;
    border-radius: 10px!important;
    -webkit-box-shadow: 0 4px 10px 0 rgba(0,0,0,.5)!important;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.5)!important;
    font-size: 14px;
  }
}

// 重置开关样式
.MuiSwitch-root {
  .MuiSwitch-switchBase {
    &.Mui-checked {
      color: #FA2256;
      &:hover {
        background-color: rgba(250, 34, 86, .2);
      }
    }
    &.Mui-checked+.MuiSwitch-track {
      background-color: #FA2256;
    }
  }
}


// 重置弹窗滚动条
.MuiMenu-paper.MuiPaper-root{
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
    }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #808080;
    }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #404040;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-corner {
    // display: none;
    opacity: 0;
  }
}

.MuiToggleButton-root {
  font-size: 14px!important;
  font-weight: bold!important;
  border: none!important;
  background-color: #1E1F25!important;
  padding: 10px 12px!important;
  &:hover {
    background-color: #282C38!important;
  }
  .Mui-disabled {
    color: #757B8C!important;
    border: none!important;
  }
  &.Mui-selected {
    background-color: #3C4254!important;
  }

}
.MuiTooltip-popper {
  z-index: 99!important;
}
