a {
  color: #7e8c8d;
  -webkit-backface-visibility: hidden;
  text-decoration: none;
}
body {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
  font-size: 14px;
  line-height: unset !important;
  font-family: Helvetica, -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
}
html *,
body * {
  margin: 0;
  padding: 0;
}
body > iframe {
  display: none;
}
.rest-scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.rest-scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #808080;
}
.rest-scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #404040;
  border-radius: 10px;
}
.rest-scrollbar::-webkit-scrollbar-corner {
  opacity: 0;
}
.hidden-scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  display: none !important;
}
.rest-primary-btn::before {
  background: none;
}
.rest-primary-btn:hover::before {
  opacity: 1 !important;
  background: #FF7E9D !important;
}
.rest-default-btn::before {
  background: none;
}
.rest-default-btn:hover::before {
  opacity: 1 !important;
  background: #3C4254 !important;
}
.v-input__control .v-input__slot {
  min-height: unset !important;
}
.v-input__control .v-input__slot fieldset {
  border: 1px solid #3C4254;
}
.v-input__control .v-input__slot .v-text-field__slot .v-label {
  top: 8px !important;
  color: #757B8C;
  font-size: 14px;
}
.v-application {
  font-family: Helvetica,Impact !important;
  line-height: normal;
  font-size: 14px;
}
.v-application .v-application--wrap p {
  margin-bottom: 0px;
}
.v-pagination__item {
  height: 28px !important;
  min-width: 28px !important;
}
.v-data-table__wrapper {
  background-color: #1e1f25;
}
.v-data-table__wrapper .v-data-table-header tr th {
  background: #1e1f25 !important;
}
.v-data-table__wrapper table .text-start {
  white-space: nowrap;
  height: 100%;
}
.v-data-table__wrapper table tbody tr td {
  font-size: 16px !important;
}
.v-data-table__wrapper table tbody tr:hover {
  background: rgba(47, 50, 65, 0.5) !important;
}
.v-menu__content {
  background: rgba(60, 66, 84, 0.2) !important;
  backdrop-filter: blur(14px);
  border: 1px solid #3C4254;
  padding: 0px !important;
  min-width: 0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5) !important;
}
.v-btn {
  letter-spacing: 0 !important;
  min-width: 0 !important;
}
.v-application .v-progress-circular.v-progress-circular {
  color: #757B8C !important;
}
.v-application .v-progress-linear__background.primary {
  background-color: #757B8C !important;
  border-color: #757B8C !important;
}
.v-application .v-progress-linear__indeterminate.primary {
  background: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%) !important;
}
.v-tooltip__content {
  padding: 10px 18px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: 700;
  color: #fff;
  background: rgba(60, 66, 84, 0.2);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #3c4254;
  backdrop-filter: blur(12px);
  border-radius: 6px;
}
.reset-mui-popover-menu .MuiMenu-paper {
  background: rgba(60, 66, 84, 0.2) !important;
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  border: 1px solid #3c4254;
  padding: 0!important;
  min-width: 0!important;
  border-radius: 10px!important;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5) !important;
  margin-top: 6px;
}
.reset-mui-popover-menu .MuiMenu-paper > ul {
  padding: 0 !important;
}
.reset-linearProgress {
  background-color: rgba(117, 123, 140, 0.3) !important;
  position: absolute !important;
  width: 100%;
}
.reset-linearProgress span {
  background: linear-gradient(132deg, #FA2256 0%, #E92E79 37%, #A45CFF 100%) !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 1px !important;
}
.MuiCheckbox-root.Mui-checked {
  color: #fa2256 !important;
  caret-color: #fa2256 !important;
}
.MuiButton-root {
  text-transform: initial !important;
}
.reset-default-black-button:hover {
  background: #3c4254 !important;
}
.reset-mui-table > div::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
.reset-mui-table > div::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #808080;
}
.reset-mui-table > div::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #404040;
  border-radius: 10px;
}
.reset-mui-table > div::-webkit-scrollbar-corner {
  opacity: 0;
}
.reset-mui-table .MuiPaper-root {
  border: none !important;
  background-color: #1e1f25 !important;
  border-radius: 4px !important;
  flex: 1;
}
.reset-mui-table .MuiTable-root {
  height: unset !important;
}
.reset-mui-table .MuiTableCell-root {
  border-color: #41424C;
  background-color: #1e1f25 !important;
}
.reset-mui-table .MuiTableHead-root .MuiTableRow-root {
  height: 48px;
  white-space: nowrap;
}
.reset-mui-table .MuiTableHead-root .MuiTableRow-root:hover {
  background-color: unset !important;
}
.reset-mui-table .MuiTableHead-root .MuiTableCell-root {
  padding: 0 16px;
}
.reset-mui-table .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root,
.reset-mui-table .MuiTableBody-root .MuiTableRow-root.selected .MuiTableCell-root {
  background-color: rgba(47, 50, 65, 0.5) !important;
}
.reset-pagination .MuiPaginationItem-root {
  width: 28px !important;
  height: 28px !important;
  margin: 0 3px !important;
}
.reset-pagination .MuiPaginationItem-root.Mui-selected {
  background-color: #FA2256 !important;
}
.MuiTooltip-popper > div {
  background: rgba(60, 66, 84, 0.2) !important;
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  border: 1px solid #3c4254;
  padding: 12px 18px !important;
  min-width: 0!important;
  border-radius: 10px!important;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5) !important;
  font-size: 14px;
}
.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  color: #FA2256;
}
.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked:hover {
  background-color: rgba(250, 34, 86, 0.2);
}
.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #FA2256;
}
.MuiMenu-paper.MuiPaper-root::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.MuiMenu-paper.MuiPaper-root::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #808080;
}
.MuiMenu-paper.MuiPaper-root::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #404040;
  border-radius: 10px;
}
.MuiMenu-paper.MuiPaper-root::-webkit-scrollbar-corner {
  opacity: 0;
}
.MuiToggleButton-root {
  font-size: 14px!important;
  font-weight: bold!important;
  border: none!important;
  background-color: #1E1F25 !important;
  padding: 10px 12px!important;
}
.MuiToggleButton-root:hover {
  background-color: #282C38 !important;
}
.MuiToggleButton-root .Mui-disabled {
  color: #757B8C !important;
  border: none!important;
}
.MuiToggleButton-root.Mui-selected {
  background-color: #3C4254 !important;
}
.MuiTooltip-popper {
  z-index: 99!important;
}
.flex {
  display: flex;
  flex: initial !important;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.flex-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column-c {
  display: flex;
  align-items: center;
}
.color_f {
  color: #fff;
}
.font_b {
  font-weight: 600;
}
.border-color-0 {
  border: 1px solid #000;
}
.cursor-p {
  cursor: pointer;
}
.text-c {
  text-align: center;
}
.p-100 {
  padding: 100px;
}
.p-t-100 {
  padding-top: 100px;
}
.p-r-100 {
  padding-right: 100px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-l-100 {
  padding-left: 100px;
}
.m-100 {
  margin: 100px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-r-100 {
  margin-right: 100px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-l-100 {
  margin-left: 100px;
}
.fz-100 {
  font-size: 100px;
}
.width100 {
  width: 100%;
}
.p-99 {
  padding: 99px;
}
.p-t-99 {
  padding-top: 99px;
}
.p-r-99 {
  padding-right: 99px;
}
.p-b-99 {
  padding-bottom: 99px;
}
.p-l-99 {
  padding-left: 99px;
}
.m-99 {
  margin: 99px;
}
.m-t-99 {
  margin-top: 99px;
}
.m-r-99 {
  margin-right: 99px;
}
.m-b-99 {
  margin-bottom: 99px;
}
.m-l-99 {
  margin-left: 99px;
}
.fz-99 {
  font-size: 99px;
}
.width99 {
  width: 99%;
}
.p-98 {
  padding: 98px;
}
.p-t-98 {
  padding-top: 98px;
}
.p-r-98 {
  padding-right: 98px;
}
.p-b-98 {
  padding-bottom: 98px;
}
.p-l-98 {
  padding-left: 98px;
}
.m-98 {
  margin: 98px;
}
.m-t-98 {
  margin-top: 98px;
}
.m-r-98 {
  margin-right: 98px;
}
.m-b-98 {
  margin-bottom: 98px;
}
.m-l-98 {
  margin-left: 98px;
}
.fz-98 {
  font-size: 98px;
}
.width98 {
  width: 98%;
}
.p-97 {
  padding: 97px;
}
.p-t-97 {
  padding-top: 97px;
}
.p-r-97 {
  padding-right: 97px;
}
.p-b-97 {
  padding-bottom: 97px;
}
.p-l-97 {
  padding-left: 97px;
}
.m-97 {
  margin: 97px;
}
.m-t-97 {
  margin-top: 97px;
}
.m-r-97 {
  margin-right: 97px;
}
.m-b-97 {
  margin-bottom: 97px;
}
.m-l-97 {
  margin-left: 97px;
}
.fz-97 {
  font-size: 97px;
}
.width97 {
  width: 97%;
}
.p-96 {
  padding: 96px;
}
.p-t-96 {
  padding-top: 96px;
}
.p-r-96 {
  padding-right: 96px;
}
.p-b-96 {
  padding-bottom: 96px;
}
.p-l-96 {
  padding-left: 96px;
}
.m-96 {
  margin: 96px;
}
.m-t-96 {
  margin-top: 96px;
}
.m-r-96 {
  margin-right: 96px;
}
.m-b-96 {
  margin-bottom: 96px;
}
.m-l-96 {
  margin-left: 96px;
}
.fz-96 {
  font-size: 96px;
}
.width96 {
  width: 96%;
}
.p-95 {
  padding: 95px;
}
.p-t-95 {
  padding-top: 95px;
}
.p-r-95 {
  padding-right: 95px;
}
.p-b-95 {
  padding-bottom: 95px;
}
.p-l-95 {
  padding-left: 95px;
}
.m-95 {
  margin: 95px;
}
.m-t-95 {
  margin-top: 95px;
}
.m-r-95 {
  margin-right: 95px;
}
.m-b-95 {
  margin-bottom: 95px;
}
.m-l-95 {
  margin-left: 95px;
}
.fz-95 {
  font-size: 95px;
}
.width95 {
  width: 95%;
}
.p-94 {
  padding: 94px;
}
.p-t-94 {
  padding-top: 94px;
}
.p-r-94 {
  padding-right: 94px;
}
.p-b-94 {
  padding-bottom: 94px;
}
.p-l-94 {
  padding-left: 94px;
}
.m-94 {
  margin: 94px;
}
.m-t-94 {
  margin-top: 94px;
}
.m-r-94 {
  margin-right: 94px;
}
.m-b-94 {
  margin-bottom: 94px;
}
.m-l-94 {
  margin-left: 94px;
}
.fz-94 {
  font-size: 94px;
}
.width94 {
  width: 94%;
}
.p-93 {
  padding: 93px;
}
.p-t-93 {
  padding-top: 93px;
}
.p-r-93 {
  padding-right: 93px;
}
.p-b-93 {
  padding-bottom: 93px;
}
.p-l-93 {
  padding-left: 93px;
}
.m-93 {
  margin: 93px;
}
.m-t-93 {
  margin-top: 93px;
}
.m-r-93 {
  margin-right: 93px;
}
.m-b-93 {
  margin-bottom: 93px;
}
.m-l-93 {
  margin-left: 93px;
}
.fz-93 {
  font-size: 93px;
}
.width93 {
  width: 93%;
}
.p-92 {
  padding: 92px;
}
.p-t-92 {
  padding-top: 92px;
}
.p-r-92 {
  padding-right: 92px;
}
.p-b-92 {
  padding-bottom: 92px;
}
.p-l-92 {
  padding-left: 92px;
}
.m-92 {
  margin: 92px;
}
.m-t-92 {
  margin-top: 92px;
}
.m-r-92 {
  margin-right: 92px;
}
.m-b-92 {
  margin-bottom: 92px;
}
.m-l-92 {
  margin-left: 92px;
}
.fz-92 {
  font-size: 92px;
}
.width92 {
  width: 92%;
}
.p-91 {
  padding: 91px;
}
.p-t-91 {
  padding-top: 91px;
}
.p-r-91 {
  padding-right: 91px;
}
.p-b-91 {
  padding-bottom: 91px;
}
.p-l-91 {
  padding-left: 91px;
}
.m-91 {
  margin: 91px;
}
.m-t-91 {
  margin-top: 91px;
}
.m-r-91 {
  margin-right: 91px;
}
.m-b-91 {
  margin-bottom: 91px;
}
.m-l-91 {
  margin-left: 91px;
}
.fz-91 {
  font-size: 91px;
}
.width91 {
  width: 91%;
}
.p-90 {
  padding: 90px;
}
.p-t-90 {
  padding-top: 90px;
}
.p-r-90 {
  padding-right: 90px;
}
.p-b-90 {
  padding-bottom: 90px;
}
.p-l-90 {
  padding-left: 90px;
}
.m-90 {
  margin: 90px;
}
.m-t-90 {
  margin-top: 90px;
}
.m-r-90 {
  margin-right: 90px;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-l-90 {
  margin-left: 90px;
}
.fz-90 {
  font-size: 90px;
}
.width90 {
  width: 90%;
}
.p-89 {
  padding: 89px;
}
.p-t-89 {
  padding-top: 89px;
}
.p-r-89 {
  padding-right: 89px;
}
.p-b-89 {
  padding-bottom: 89px;
}
.p-l-89 {
  padding-left: 89px;
}
.m-89 {
  margin: 89px;
}
.m-t-89 {
  margin-top: 89px;
}
.m-r-89 {
  margin-right: 89px;
}
.m-b-89 {
  margin-bottom: 89px;
}
.m-l-89 {
  margin-left: 89px;
}
.fz-89 {
  font-size: 89px;
}
.width89 {
  width: 89%;
}
.p-88 {
  padding: 88px;
}
.p-t-88 {
  padding-top: 88px;
}
.p-r-88 {
  padding-right: 88px;
}
.p-b-88 {
  padding-bottom: 88px;
}
.p-l-88 {
  padding-left: 88px;
}
.m-88 {
  margin: 88px;
}
.m-t-88 {
  margin-top: 88px;
}
.m-r-88 {
  margin-right: 88px;
}
.m-b-88 {
  margin-bottom: 88px;
}
.m-l-88 {
  margin-left: 88px;
}
.fz-88 {
  font-size: 88px;
}
.width88 {
  width: 88%;
}
.p-87 {
  padding: 87px;
}
.p-t-87 {
  padding-top: 87px;
}
.p-r-87 {
  padding-right: 87px;
}
.p-b-87 {
  padding-bottom: 87px;
}
.p-l-87 {
  padding-left: 87px;
}
.m-87 {
  margin: 87px;
}
.m-t-87 {
  margin-top: 87px;
}
.m-r-87 {
  margin-right: 87px;
}
.m-b-87 {
  margin-bottom: 87px;
}
.m-l-87 {
  margin-left: 87px;
}
.fz-87 {
  font-size: 87px;
}
.width87 {
  width: 87%;
}
.p-86 {
  padding: 86px;
}
.p-t-86 {
  padding-top: 86px;
}
.p-r-86 {
  padding-right: 86px;
}
.p-b-86 {
  padding-bottom: 86px;
}
.p-l-86 {
  padding-left: 86px;
}
.m-86 {
  margin: 86px;
}
.m-t-86 {
  margin-top: 86px;
}
.m-r-86 {
  margin-right: 86px;
}
.m-b-86 {
  margin-bottom: 86px;
}
.m-l-86 {
  margin-left: 86px;
}
.fz-86 {
  font-size: 86px;
}
.width86 {
  width: 86%;
}
.p-85 {
  padding: 85px;
}
.p-t-85 {
  padding-top: 85px;
}
.p-r-85 {
  padding-right: 85px;
}
.p-b-85 {
  padding-bottom: 85px;
}
.p-l-85 {
  padding-left: 85px;
}
.m-85 {
  margin: 85px;
}
.m-t-85 {
  margin-top: 85px;
}
.m-r-85 {
  margin-right: 85px;
}
.m-b-85 {
  margin-bottom: 85px;
}
.m-l-85 {
  margin-left: 85px;
}
.fz-85 {
  font-size: 85px;
}
.width85 {
  width: 85%;
}
.p-84 {
  padding: 84px;
}
.p-t-84 {
  padding-top: 84px;
}
.p-r-84 {
  padding-right: 84px;
}
.p-b-84 {
  padding-bottom: 84px;
}
.p-l-84 {
  padding-left: 84px;
}
.m-84 {
  margin: 84px;
}
.m-t-84 {
  margin-top: 84px;
}
.m-r-84 {
  margin-right: 84px;
}
.m-b-84 {
  margin-bottom: 84px;
}
.m-l-84 {
  margin-left: 84px;
}
.fz-84 {
  font-size: 84px;
}
.width84 {
  width: 84%;
}
.p-83 {
  padding: 83px;
}
.p-t-83 {
  padding-top: 83px;
}
.p-r-83 {
  padding-right: 83px;
}
.p-b-83 {
  padding-bottom: 83px;
}
.p-l-83 {
  padding-left: 83px;
}
.m-83 {
  margin: 83px;
}
.m-t-83 {
  margin-top: 83px;
}
.m-r-83 {
  margin-right: 83px;
}
.m-b-83 {
  margin-bottom: 83px;
}
.m-l-83 {
  margin-left: 83px;
}
.fz-83 {
  font-size: 83px;
}
.width83 {
  width: 83%;
}
.p-82 {
  padding: 82px;
}
.p-t-82 {
  padding-top: 82px;
}
.p-r-82 {
  padding-right: 82px;
}
.p-b-82 {
  padding-bottom: 82px;
}
.p-l-82 {
  padding-left: 82px;
}
.m-82 {
  margin: 82px;
}
.m-t-82 {
  margin-top: 82px;
}
.m-r-82 {
  margin-right: 82px;
}
.m-b-82 {
  margin-bottom: 82px;
}
.m-l-82 {
  margin-left: 82px;
}
.fz-82 {
  font-size: 82px;
}
.width82 {
  width: 82%;
}
.p-81 {
  padding: 81px;
}
.p-t-81 {
  padding-top: 81px;
}
.p-r-81 {
  padding-right: 81px;
}
.p-b-81 {
  padding-bottom: 81px;
}
.p-l-81 {
  padding-left: 81px;
}
.m-81 {
  margin: 81px;
}
.m-t-81 {
  margin-top: 81px;
}
.m-r-81 {
  margin-right: 81px;
}
.m-b-81 {
  margin-bottom: 81px;
}
.m-l-81 {
  margin-left: 81px;
}
.fz-81 {
  font-size: 81px;
}
.width81 {
  width: 81%;
}
.p-80 {
  padding: 80px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-r-80 {
  padding-right: 80px;
}
.p-b-80 {
  padding-bottom: 80px;
}
.p-l-80 {
  padding-left: 80px;
}
.m-80 {
  margin: 80px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-r-80 {
  margin-right: 80px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-l-80 {
  margin-left: 80px;
}
.fz-80 {
  font-size: 80px;
}
.width80 {
  width: 80%;
}
.p-79 {
  padding: 79px;
}
.p-t-79 {
  padding-top: 79px;
}
.p-r-79 {
  padding-right: 79px;
}
.p-b-79 {
  padding-bottom: 79px;
}
.p-l-79 {
  padding-left: 79px;
}
.m-79 {
  margin: 79px;
}
.m-t-79 {
  margin-top: 79px;
}
.m-r-79 {
  margin-right: 79px;
}
.m-b-79 {
  margin-bottom: 79px;
}
.m-l-79 {
  margin-left: 79px;
}
.fz-79 {
  font-size: 79px;
}
.width79 {
  width: 79%;
}
.p-78 {
  padding: 78px;
}
.p-t-78 {
  padding-top: 78px;
}
.p-r-78 {
  padding-right: 78px;
}
.p-b-78 {
  padding-bottom: 78px;
}
.p-l-78 {
  padding-left: 78px;
}
.m-78 {
  margin: 78px;
}
.m-t-78 {
  margin-top: 78px;
}
.m-r-78 {
  margin-right: 78px;
}
.m-b-78 {
  margin-bottom: 78px;
}
.m-l-78 {
  margin-left: 78px;
}
.fz-78 {
  font-size: 78px;
}
.width78 {
  width: 78%;
}
.p-77 {
  padding: 77px;
}
.p-t-77 {
  padding-top: 77px;
}
.p-r-77 {
  padding-right: 77px;
}
.p-b-77 {
  padding-bottom: 77px;
}
.p-l-77 {
  padding-left: 77px;
}
.m-77 {
  margin: 77px;
}
.m-t-77 {
  margin-top: 77px;
}
.m-r-77 {
  margin-right: 77px;
}
.m-b-77 {
  margin-bottom: 77px;
}
.m-l-77 {
  margin-left: 77px;
}
.fz-77 {
  font-size: 77px;
}
.width77 {
  width: 77%;
}
.p-76 {
  padding: 76px;
}
.p-t-76 {
  padding-top: 76px;
}
.p-r-76 {
  padding-right: 76px;
}
.p-b-76 {
  padding-bottom: 76px;
}
.p-l-76 {
  padding-left: 76px;
}
.m-76 {
  margin: 76px;
}
.m-t-76 {
  margin-top: 76px;
}
.m-r-76 {
  margin-right: 76px;
}
.m-b-76 {
  margin-bottom: 76px;
}
.m-l-76 {
  margin-left: 76px;
}
.fz-76 {
  font-size: 76px;
}
.width76 {
  width: 76%;
}
.p-75 {
  padding: 75px;
}
.p-t-75 {
  padding-top: 75px;
}
.p-r-75 {
  padding-right: 75px;
}
.p-b-75 {
  padding-bottom: 75px;
}
.p-l-75 {
  padding-left: 75px;
}
.m-75 {
  margin: 75px;
}
.m-t-75 {
  margin-top: 75px;
}
.m-r-75 {
  margin-right: 75px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-l-75 {
  margin-left: 75px;
}
.fz-75 {
  font-size: 75px;
}
.width75 {
  width: 75%;
}
.p-74 {
  padding: 74px;
}
.p-t-74 {
  padding-top: 74px;
}
.p-r-74 {
  padding-right: 74px;
}
.p-b-74 {
  padding-bottom: 74px;
}
.p-l-74 {
  padding-left: 74px;
}
.m-74 {
  margin: 74px;
}
.m-t-74 {
  margin-top: 74px;
}
.m-r-74 {
  margin-right: 74px;
}
.m-b-74 {
  margin-bottom: 74px;
}
.m-l-74 {
  margin-left: 74px;
}
.fz-74 {
  font-size: 74px;
}
.width74 {
  width: 74%;
}
.p-73 {
  padding: 73px;
}
.p-t-73 {
  padding-top: 73px;
}
.p-r-73 {
  padding-right: 73px;
}
.p-b-73 {
  padding-bottom: 73px;
}
.p-l-73 {
  padding-left: 73px;
}
.m-73 {
  margin: 73px;
}
.m-t-73 {
  margin-top: 73px;
}
.m-r-73 {
  margin-right: 73px;
}
.m-b-73 {
  margin-bottom: 73px;
}
.m-l-73 {
  margin-left: 73px;
}
.fz-73 {
  font-size: 73px;
}
.width73 {
  width: 73%;
}
.p-72 {
  padding: 72px;
}
.p-t-72 {
  padding-top: 72px;
}
.p-r-72 {
  padding-right: 72px;
}
.p-b-72 {
  padding-bottom: 72px;
}
.p-l-72 {
  padding-left: 72px;
}
.m-72 {
  margin: 72px;
}
.m-t-72 {
  margin-top: 72px;
}
.m-r-72 {
  margin-right: 72px;
}
.m-b-72 {
  margin-bottom: 72px;
}
.m-l-72 {
  margin-left: 72px;
}
.fz-72 {
  font-size: 72px;
}
.width72 {
  width: 72%;
}
.p-71 {
  padding: 71px;
}
.p-t-71 {
  padding-top: 71px;
}
.p-r-71 {
  padding-right: 71px;
}
.p-b-71 {
  padding-bottom: 71px;
}
.p-l-71 {
  padding-left: 71px;
}
.m-71 {
  margin: 71px;
}
.m-t-71 {
  margin-top: 71px;
}
.m-r-71 {
  margin-right: 71px;
}
.m-b-71 {
  margin-bottom: 71px;
}
.m-l-71 {
  margin-left: 71px;
}
.fz-71 {
  font-size: 71px;
}
.width71 {
  width: 71%;
}
.p-70 {
  padding: 70px;
}
.p-t-70 {
  padding-top: 70px;
}
.p-r-70 {
  padding-right: 70px;
}
.p-b-70 {
  padding-bottom: 70px;
}
.p-l-70 {
  padding-left: 70px;
}
.m-70 {
  margin: 70px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-r-70 {
  margin-right: 70px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-l-70 {
  margin-left: 70px;
}
.fz-70 {
  font-size: 70px;
}
.width70 {
  width: 70%;
}
.p-69 {
  padding: 69px;
}
.p-t-69 {
  padding-top: 69px;
}
.p-r-69 {
  padding-right: 69px;
}
.p-b-69 {
  padding-bottom: 69px;
}
.p-l-69 {
  padding-left: 69px;
}
.m-69 {
  margin: 69px;
}
.m-t-69 {
  margin-top: 69px;
}
.m-r-69 {
  margin-right: 69px;
}
.m-b-69 {
  margin-bottom: 69px;
}
.m-l-69 {
  margin-left: 69px;
}
.fz-69 {
  font-size: 69px;
}
.width69 {
  width: 69%;
}
.p-68 {
  padding: 68px;
}
.p-t-68 {
  padding-top: 68px;
}
.p-r-68 {
  padding-right: 68px;
}
.p-b-68 {
  padding-bottom: 68px;
}
.p-l-68 {
  padding-left: 68px;
}
.m-68 {
  margin: 68px;
}
.m-t-68 {
  margin-top: 68px;
}
.m-r-68 {
  margin-right: 68px;
}
.m-b-68 {
  margin-bottom: 68px;
}
.m-l-68 {
  margin-left: 68px;
}
.fz-68 {
  font-size: 68px;
}
.width68 {
  width: 68%;
}
.p-67 {
  padding: 67px;
}
.p-t-67 {
  padding-top: 67px;
}
.p-r-67 {
  padding-right: 67px;
}
.p-b-67 {
  padding-bottom: 67px;
}
.p-l-67 {
  padding-left: 67px;
}
.m-67 {
  margin: 67px;
}
.m-t-67 {
  margin-top: 67px;
}
.m-r-67 {
  margin-right: 67px;
}
.m-b-67 {
  margin-bottom: 67px;
}
.m-l-67 {
  margin-left: 67px;
}
.fz-67 {
  font-size: 67px;
}
.width67 {
  width: 67%;
}
.p-66 {
  padding: 66px;
}
.p-t-66 {
  padding-top: 66px;
}
.p-r-66 {
  padding-right: 66px;
}
.p-b-66 {
  padding-bottom: 66px;
}
.p-l-66 {
  padding-left: 66px;
}
.m-66 {
  margin: 66px;
}
.m-t-66 {
  margin-top: 66px;
}
.m-r-66 {
  margin-right: 66px;
}
.m-b-66 {
  margin-bottom: 66px;
}
.m-l-66 {
  margin-left: 66px;
}
.fz-66 {
  font-size: 66px;
}
.width66 {
  width: 66%;
}
.p-65 {
  padding: 65px;
}
.p-t-65 {
  padding-top: 65px;
}
.p-r-65 {
  padding-right: 65px;
}
.p-b-65 {
  padding-bottom: 65px;
}
.p-l-65 {
  padding-left: 65px;
}
.m-65 {
  margin: 65px;
}
.m-t-65 {
  margin-top: 65px;
}
.m-r-65 {
  margin-right: 65px;
}
.m-b-65 {
  margin-bottom: 65px;
}
.m-l-65 {
  margin-left: 65px;
}
.fz-65 {
  font-size: 65px;
}
.width65 {
  width: 65%;
}
.p-64 {
  padding: 64px;
}
.p-t-64 {
  padding-top: 64px;
}
.p-r-64 {
  padding-right: 64px;
}
.p-b-64 {
  padding-bottom: 64px;
}
.p-l-64 {
  padding-left: 64px;
}
.m-64 {
  margin: 64px;
}
.m-t-64 {
  margin-top: 64px;
}
.m-r-64 {
  margin-right: 64px;
}
.m-b-64 {
  margin-bottom: 64px;
}
.m-l-64 {
  margin-left: 64px;
}
.fz-64 {
  font-size: 64px;
}
.width64 {
  width: 64%;
}
.p-63 {
  padding: 63px;
}
.p-t-63 {
  padding-top: 63px;
}
.p-r-63 {
  padding-right: 63px;
}
.p-b-63 {
  padding-bottom: 63px;
}
.p-l-63 {
  padding-left: 63px;
}
.m-63 {
  margin: 63px;
}
.m-t-63 {
  margin-top: 63px;
}
.m-r-63 {
  margin-right: 63px;
}
.m-b-63 {
  margin-bottom: 63px;
}
.m-l-63 {
  margin-left: 63px;
}
.fz-63 {
  font-size: 63px;
}
.width63 {
  width: 63%;
}
.p-62 {
  padding: 62px;
}
.p-t-62 {
  padding-top: 62px;
}
.p-r-62 {
  padding-right: 62px;
}
.p-b-62 {
  padding-bottom: 62px;
}
.p-l-62 {
  padding-left: 62px;
}
.m-62 {
  margin: 62px;
}
.m-t-62 {
  margin-top: 62px;
}
.m-r-62 {
  margin-right: 62px;
}
.m-b-62 {
  margin-bottom: 62px;
}
.m-l-62 {
  margin-left: 62px;
}
.fz-62 {
  font-size: 62px;
}
.width62 {
  width: 62%;
}
.p-61 {
  padding: 61px;
}
.p-t-61 {
  padding-top: 61px;
}
.p-r-61 {
  padding-right: 61px;
}
.p-b-61 {
  padding-bottom: 61px;
}
.p-l-61 {
  padding-left: 61px;
}
.m-61 {
  margin: 61px;
}
.m-t-61 {
  margin-top: 61px;
}
.m-r-61 {
  margin-right: 61px;
}
.m-b-61 {
  margin-bottom: 61px;
}
.m-l-61 {
  margin-left: 61px;
}
.fz-61 {
  font-size: 61px;
}
.width61 {
  width: 61%;
}
.p-60 {
  padding: 60px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-l-60 {
  padding-left: 60px;
}
.m-60 {
  margin: 60px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-r-60 {
  margin-right: 60px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-l-60 {
  margin-left: 60px;
}
.fz-60 {
  font-size: 60px;
}
.width60 {
  width: 60%;
}
.p-59 {
  padding: 59px;
}
.p-t-59 {
  padding-top: 59px;
}
.p-r-59 {
  padding-right: 59px;
}
.p-b-59 {
  padding-bottom: 59px;
}
.p-l-59 {
  padding-left: 59px;
}
.m-59 {
  margin: 59px;
}
.m-t-59 {
  margin-top: 59px;
}
.m-r-59 {
  margin-right: 59px;
}
.m-b-59 {
  margin-bottom: 59px;
}
.m-l-59 {
  margin-left: 59px;
}
.fz-59 {
  font-size: 59px;
}
.width59 {
  width: 59%;
}
.p-58 {
  padding: 58px;
}
.p-t-58 {
  padding-top: 58px;
}
.p-r-58 {
  padding-right: 58px;
}
.p-b-58 {
  padding-bottom: 58px;
}
.p-l-58 {
  padding-left: 58px;
}
.m-58 {
  margin: 58px;
}
.m-t-58 {
  margin-top: 58px;
}
.m-r-58 {
  margin-right: 58px;
}
.m-b-58 {
  margin-bottom: 58px;
}
.m-l-58 {
  margin-left: 58px;
}
.fz-58 {
  font-size: 58px;
}
.width58 {
  width: 58%;
}
.p-57 {
  padding: 57px;
}
.p-t-57 {
  padding-top: 57px;
}
.p-r-57 {
  padding-right: 57px;
}
.p-b-57 {
  padding-bottom: 57px;
}
.p-l-57 {
  padding-left: 57px;
}
.m-57 {
  margin: 57px;
}
.m-t-57 {
  margin-top: 57px;
}
.m-r-57 {
  margin-right: 57px;
}
.m-b-57 {
  margin-bottom: 57px;
}
.m-l-57 {
  margin-left: 57px;
}
.fz-57 {
  font-size: 57px;
}
.width57 {
  width: 57%;
}
.p-56 {
  padding: 56px;
}
.p-t-56 {
  padding-top: 56px;
}
.p-r-56 {
  padding-right: 56px;
}
.p-b-56 {
  padding-bottom: 56px;
}
.p-l-56 {
  padding-left: 56px;
}
.m-56 {
  margin: 56px;
}
.m-t-56 {
  margin-top: 56px;
}
.m-r-56 {
  margin-right: 56px;
}
.m-b-56 {
  margin-bottom: 56px;
}
.m-l-56 {
  margin-left: 56px;
}
.fz-56 {
  font-size: 56px;
}
.width56 {
  width: 56%;
}
.p-55 {
  padding: 55px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-r-55 {
  padding-right: 55px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-l-55 {
  padding-left: 55px;
}
.m-55 {
  margin: 55px;
}
.m-t-55 {
  margin-top: 55px;
}
.m-r-55 {
  margin-right: 55px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-l-55 {
  margin-left: 55px;
}
.fz-55 {
  font-size: 55px;
}
.width55 {
  width: 55%;
}
.p-54 {
  padding: 54px;
}
.p-t-54 {
  padding-top: 54px;
}
.p-r-54 {
  padding-right: 54px;
}
.p-b-54 {
  padding-bottom: 54px;
}
.p-l-54 {
  padding-left: 54px;
}
.m-54 {
  margin: 54px;
}
.m-t-54 {
  margin-top: 54px;
}
.m-r-54 {
  margin-right: 54px;
}
.m-b-54 {
  margin-bottom: 54px;
}
.m-l-54 {
  margin-left: 54px;
}
.fz-54 {
  font-size: 54px;
}
.width54 {
  width: 54%;
}
.p-53 {
  padding: 53px;
}
.p-t-53 {
  padding-top: 53px;
}
.p-r-53 {
  padding-right: 53px;
}
.p-b-53 {
  padding-bottom: 53px;
}
.p-l-53 {
  padding-left: 53px;
}
.m-53 {
  margin: 53px;
}
.m-t-53 {
  margin-top: 53px;
}
.m-r-53 {
  margin-right: 53px;
}
.m-b-53 {
  margin-bottom: 53px;
}
.m-l-53 {
  margin-left: 53px;
}
.fz-53 {
  font-size: 53px;
}
.width53 {
  width: 53%;
}
.p-52 {
  padding: 52px;
}
.p-t-52 {
  padding-top: 52px;
}
.p-r-52 {
  padding-right: 52px;
}
.p-b-52 {
  padding-bottom: 52px;
}
.p-l-52 {
  padding-left: 52px;
}
.m-52 {
  margin: 52px;
}
.m-t-52 {
  margin-top: 52px;
}
.m-r-52 {
  margin-right: 52px;
}
.m-b-52 {
  margin-bottom: 52px;
}
.m-l-52 {
  margin-left: 52px;
}
.fz-52 {
  font-size: 52px;
}
.width52 {
  width: 52%;
}
.p-51 {
  padding: 51px;
}
.p-t-51 {
  padding-top: 51px;
}
.p-r-51 {
  padding-right: 51px;
}
.p-b-51 {
  padding-bottom: 51px;
}
.p-l-51 {
  padding-left: 51px;
}
.m-51 {
  margin: 51px;
}
.m-t-51 {
  margin-top: 51px;
}
.m-r-51 {
  margin-right: 51px;
}
.m-b-51 {
  margin-bottom: 51px;
}
.m-l-51 {
  margin-left: 51px;
}
.fz-51 {
  font-size: 51px;
}
.width51 {
  width: 51%;
}
.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.fz-50 {
  font-size: 50px;
}
.width50 {
  width: 50%;
}
.p-49 {
  padding: 49px;
}
.p-t-49 {
  padding-top: 49px;
}
.p-r-49 {
  padding-right: 49px;
}
.p-b-49 {
  padding-bottom: 49px;
}
.p-l-49 {
  padding-left: 49px;
}
.m-49 {
  margin: 49px;
}
.m-t-49 {
  margin-top: 49px;
}
.m-r-49 {
  margin-right: 49px;
}
.m-b-49 {
  margin-bottom: 49px;
}
.m-l-49 {
  margin-left: 49px;
}
.fz-49 {
  font-size: 49px;
}
.width49 {
  width: 49%;
}
.p-48 {
  padding: 48px;
}
.p-t-48 {
  padding-top: 48px;
}
.p-r-48 {
  padding-right: 48px;
}
.p-b-48 {
  padding-bottom: 48px;
}
.p-l-48 {
  padding-left: 48px;
}
.m-48 {
  margin: 48px;
}
.m-t-48 {
  margin-top: 48px;
}
.m-r-48 {
  margin-right: 48px;
}
.m-b-48 {
  margin-bottom: 48px;
}
.m-l-48 {
  margin-left: 48px;
}
.fz-48 {
  font-size: 48px;
}
.width48 {
  width: 48%;
}
.p-47 {
  padding: 47px;
}
.p-t-47 {
  padding-top: 47px;
}
.p-r-47 {
  padding-right: 47px;
}
.p-b-47 {
  padding-bottom: 47px;
}
.p-l-47 {
  padding-left: 47px;
}
.m-47 {
  margin: 47px;
}
.m-t-47 {
  margin-top: 47px;
}
.m-r-47 {
  margin-right: 47px;
}
.m-b-47 {
  margin-bottom: 47px;
}
.m-l-47 {
  margin-left: 47px;
}
.fz-47 {
  font-size: 47px;
}
.width47 {
  width: 47%;
}
.p-46 {
  padding: 46px;
}
.p-t-46 {
  padding-top: 46px;
}
.p-r-46 {
  padding-right: 46px;
}
.p-b-46 {
  padding-bottom: 46px;
}
.p-l-46 {
  padding-left: 46px;
}
.m-46 {
  margin: 46px;
}
.m-t-46 {
  margin-top: 46px;
}
.m-r-46 {
  margin-right: 46px;
}
.m-b-46 {
  margin-bottom: 46px;
}
.m-l-46 {
  margin-left: 46px;
}
.fz-46 {
  font-size: 46px;
}
.width46 {
  width: 46%;
}
.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.fz-45 {
  font-size: 45px;
}
.width45 {
  width: 45%;
}
.p-44 {
  padding: 44px;
}
.p-t-44 {
  padding-top: 44px;
}
.p-r-44 {
  padding-right: 44px;
}
.p-b-44 {
  padding-bottom: 44px;
}
.p-l-44 {
  padding-left: 44px;
}
.m-44 {
  margin: 44px;
}
.m-t-44 {
  margin-top: 44px;
}
.m-r-44 {
  margin-right: 44px;
}
.m-b-44 {
  margin-bottom: 44px;
}
.m-l-44 {
  margin-left: 44px;
}
.fz-44 {
  font-size: 44px;
}
.width44 {
  width: 44%;
}
.p-43 {
  padding: 43px;
}
.p-t-43 {
  padding-top: 43px;
}
.p-r-43 {
  padding-right: 43px;
}
.p-b-43 {
  padding-bottom: 43px;
}
.p-l-43 {
  padding-left: 43px;
}
.m-43 {
  margin: 43px;
}
.m-t-43 {
  margin-top: 43px;
}
.m-r-43 {
  margin-right: 43px;
}
.m-b-43 {
  margin-bottom: 43px;
}
.m-l-43 {
  margin-left: 43px;
}
.fz-43 {
  font-size: 43px;
}
.width43 {
  width: 43%;
}
.p-42 {
  padding: 42px;
}
.p-t-42 {
  padding-top: 42px;
}
.p-r-42 {
  padding-right: 42px;
}
.p-b-42 {
  padding-bottom: 42px;
}
.p-l-42 {
  padding-left: 42px;
}
.m-42 {
  margin: 42px;
}
.m-t-42 {
  margin-top: 42px;
}
.m-r-42 {
  margin-right: 42px;
}
.m-b-42 {
  margin-bottom: 42px;
}
.m-l-42 {
  margin-left: 42px;
}
.fz-42 {
  font-size: 42px;
}
.width42 {
  width: 42%;
}
.p-41 {
  padding: 41px;
}
.p-t-41 {
  padding-top: 41px;
}
.p-r-41 {
  padding-right: 41px;
}
.p-b-41 {
  padding-bottom: 41px;
}
.p-l-41 {
  padding-left: 41px;
}
.m-41 {
  margin: 41px;
}
.m-t-41 {
  margin-top: 41px;
}
.m-r-41 {
  margin-right: 41px;
}
.m-b-41 {
  margin-bottom: 41px;
}
.m-l-41 {
  margin-left: 41px;
}
.fz-41 {
  font-size: 41px;
}
.width41 {
  width: 41%;
}
.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.fz-40 {
  font-size: 40px;
}
.width40 {
  width: 40%;
}
.p-39 {
  padding: 39px;
}
.p-t-39 {
  padding-top: 39px;
}
.p-r-39 {
  padding-right: 39px;
}
.p-b-39 {
  padding-bottom: 39px;
}
.p-l-39 {
  padding-left: 39px;
}
.m-39 {
  margin: 39px;
}
.m-t-39 {
  margin-top: 39px;
}
.m-r-39 {
  margin-right: 39px;
}
.m-b-39 {
  margin-bottom: 39px;
}
.m-l-39 {
  margin-left: 39px;
}
.fz-39 {
  font-size: 39px;
}
.width39 {
  width: 39%;
}
.p-38 {
  padding: 38px;
}
.p-t-38 {
  padding-top: 38px;
}
.p-r-38 {
  padding-right: 38px;
}
.p-b-38 {
  padding-bottom: 38px;
}
.p-l-38 {
  padding-left: 38px;
}
.m-38 {
  margin: 38px;
}
.m-t-38 {
  margin-top: 38px;
}
.m-r-38 {
  margin-right: 38px;
}
.m-b-38 {
  margin-bottom: 38px;
}
.m-l-38 {
  margin-left: 38px;
}
.fz-38 {
  font-size: 38px;
}
.width38 {
  width: 38%;
}
.p-37 {
  padding: 37px;
}
.p-t-37 {
  padding-top: 37px;
}
.p-r-37 {
  padding-right: 37px;
}
.p-b-37 {
  padding-bottom: 37px;
}
.p-l-37 {
  padding-left: 37px;
}
.m-37 {
  margin: 37px;
}
.m-t-37 {
  margin-top: 37px;
}
.m-r-37 {
  margin-right: 37px;
}
.m-b-37 {
  margin-bottom: 37px;
}
.m-l-37 {
  margin-left: 37px;
}
.fz-37 {
  font-size: 37px;
}
.width37 {
  width: 37%;
}
.p-36 {
  padding: 36px;
}
.p-t-36 {
  padding-top: 36px;
}
.p-r-36 {
  padding-right: 36px;
}
.p-b-36 {
  padding-bottom: 36px;
}
.p-l-36 {
  padding-left: 36px;
}
.m-36 {
  margin: 36px;
}
.m-t-36 {
  margin-top: 36px;
}
.m-r-36 {
  margin-right: 36px;
}
.m-b-36 {
  margin-bottom: 36px;
}
.m-l-36 {
  margin-left: 36px;
}
.fz-36 {
  font-size: 36px;
}
.width36 {
  width: 36%;
}
.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.fz-35 {
  font-size: 35px;
}
.width35 {
  width: 35%;
}
.p-34 {
  padding: 34px;
}
.p-t-34 {
  padding-top: 34px;
}
.p-r-34 {
  padding-right: 34px;
}
.p-b-34 {
  padding-bottom: 34px;
}
.p-l-34 {
  padding-left: 34px;
}
.m-34 {
  margin: 34px;
}
.m-t-34 {
  margin-top: 34px;
}
.m-r-34 {
  margin-right: 34px;
}
.m-b-34 {
  margin-bottom: 34px;
}
.m-l-34 {
  margin-left: 34px;
}
.fz-34 {
  font-size: 34px;
}
.width34 {
  width: 34%;
}
.p-33 {
  padding: 33px;
}
.p-t-33 {
  padding-top: 33px;
}
.p-r-33 {
  padding-right: 33px;
}
.p-b-33 {
  padding-bottom: 33px;
}
.p-l-33 {
  padding-left: 33px;
}
.m-33 {
  margin: 33px;
}
.m-t-33 {
  margin-top: 33px;
}
.m-r-33 {
  margin-right: 33px;
}
.m-b-33 {
  margin-bottom: 33px;
}
.m-l-33 {
  margin-left: 33px;
}
.fz-33 {
  font-size: 33px;
}
.width33 {
  width: 33%;
}
.p-32 {
  padding: 32px;
}
.p-t-32 {
  padding-top: 32px;
}
.p-r-32 {
  padding-right: 32px;
}
.p-b-32 {
  padding-bottom: 32px;
}
.p-l-32 {
  padding-left: 32px;
}
.m-32 {
  margin: 32px;
}
.m-t-32 {
  margin-top: 32px;
}
.m-r-32 {
  margin-right: 32px;
}
.m-b-32 {
  margin-bottom: 32px;
}
.m-l-32 {
  margin-left: 32px;
}
.fz-32 {
  font-size: 32px;
}
.width32 {
  width: 32%;
}
.p-31 {
  padding: 31px;
}
.p-t-31 {
  padding-top: 31px;
}
.p-r-31 {
  padding-right: 31px;
}
.p-b-31 {
  padding-bottom: 31px;
}
.p-l-31 {
  padding-left: 31px;
}
.m-31 {
  margin: 31px;
}
.m-t-31 {
  margin-top: 31px;
}
.m-r-31 {
  margin-right: 31px;
}
.m-b-31 {
  margin-bottom: 31px;
}
.m-l-31 {
  margin-left: 31px;
}
.fz-31 {
  font-size: 31px;
}
.width31 {
  width: 31%;
}
.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.fz-30 {
  font-size: 30px;
}
.width30 {
  width: 30%;
}
.p-29 {
  padding: 29px;
}
.p-t-29 {
  padding-top: 29px;
}
.p-r-29 {
  padding-right: 29px;
}
.p-b-29 {
  padding-bottom: 29px;
}
.p-l-29 {
  padding-left: 29px;
}
.m-29 {
  margin: 29px;
}
.m-t-29 {
  margin-top: 29px;
}
.m-r-29 {
  margin-right: 29px;
}
.m-b-29 {
  margin-bottom: 29px;
}
.m-l-29 {
  margin-left: 29px;
}
.fz-29 {
  font-size: 29px;
}
.width29 {
  width: 29%;
}
.p-28 {
  padding: 28px;
}
.p-t-28 {
  padding-top: 28px;
}
.p-r-28 {
  padding-right: 28px;
}
.p-b-28 {
  padding-bottom: 28px;
}
.p-l-28 {
  padding-left: 28px;
}
.m-28 {
  margin: 28px;
}
.m-t-28 {
  margin-top: 28px;
}
.m-r-28 {
  margin-right: 28px;
}
.m-b-28 {
  margin-bottom: 28px;
}
.m-l-28 {
  margin-left: 28px;
}
.fz-28 {
  font-size: 28px;
}
.width28 {
  width: 28%;
}
.p-27 {
  padding: 27px;
}
.p-t-27 {
  padding-top: 27px;
}
.p-r-27 {
  padding-right: 27px;
}
.p-b-27 {
  padding-bottom: 27px;
}
.p-l-27 {
  padding-left: 27px;
}
.m-27 {
  margin: 27px;
}
.m-t-27 {
  margin-top: 27px;
}
.m-r-27 {
  margin-right: 27px;
}
.m-b-27 {
  margin-bottom: 27px;
}
.m-l-27 {
  margin-left: 27px;
}
.fz-27 {
  font-size: 27px;
}
.width27 {
  width: 27%;
}
.p-26 {
  padding: 26px;
}
.p-t-26 {
  padding-top: 26px;
}
.p-r-26 {
  padding-right: 26px;
}
.p-b-26 {
  padding-bottom: 26px;
}
.p-l-26 {
  padding-left: 26px;
}
.m-26 {
  margin: 26px;
}
.m-t-26 {
  margin-top: 26px;
}
.m-r-26 {
  margin-right: 26px;
}
.m-b-26 {
  margin-bottom: 26px;
}
.m-l-26 {
  margin-left: 26px;
}
.fz-26 {
  font-size: 26px;
}
.width26 {
  width: 26%;
}
.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.fz-25 {
  font-size: 25px;
}
.width25 {
  width: 25%;
}
.p-24 {
  padding: 24px;
}
.p-t-24 {
  padding-top: 24px;
}
.p-r-24 {
  padding-right: 24px;
}
.p-b-24 {
  padding-bottom: 24px;
}
.p-l-24 {
  padding-left: 24px;
}
.m-24 {
  margin: 24px;
}
.m-t-24 {
  margin-top: 24px;
}
.m-r-24 {
  margin-right: 24px;
}
.m-b-24 {
  margin-bottom: 24px;
}
.m-l-24 {
  margin-left: 24px;
}
.fz-24 {
  font-size: 24px;
}
.width24 {
  width: 24%;
}
.p-23 {
  padding: 23px;
}
.p-t-23 {
  padding-top: 23px;
}
.p-r-23 {
  padding-right: 23px;
}
.p-b-23 {
  padding-bottom: 23px;
}
.p-l-23 {
  padding-left: 23px;
}
.m-23 {
  margin: 23px;
}
.m-t-23 {
  margin-top: 23px;
}
.m-r-23 {
  margin-right: 23px;
}
.m-b-23 {
  margin-bottom: 23px;
}
.m-l-23 {
  margin-left: 23px;
}
.fz-23 {
  font-size: 23px;
}
.width23 {
  width: 23%;
}
.p-22 {
  padding: 22px;
}
.p-t-22 {
  padding-top: 22px;
}
.p-r-22 {
  padding-right: 22px;
}
.p-b-22 {
  padding-bottom: 22px;
}
.p-l-22 {
  padding-left: 22px;
}
.m-22 {
  margin: 22px;
}
.m-t-22 {
  margin-top: 22px;
}
.m-r-22 {
  margin-right: 22px;
}
.m-b-22 {
  margin-bottom: 22px;
}
.m-l-22 {
  margin-left: 22px;
}
.fz-22 {
  font-size: 22px;
}
.width22 {
  width: 22%;
}
.p-21 {
  padding: 21px;
}
.p-t-21 {
  padding-top: 21px;
}
.p-r-21 {
  padding-right: 21px;
}
.p-b-21 {
  padding-bottom: 21px;
}
.p-l-21 {
  padding-left: 21px;
}
.m-21 {
  margin: 21px;
}
.m-t-21 {
  margin-top: 21px;
}
.m-r-21 {
  margin-right: 21px;
}
.m-b-21 {
  margin-bottom: 21px;
}
.m-l-21 {
  margin-left: 21px;
}
.fz-21 {
  font-size: 21px;
}
.width21 {
  width: 21%;
}
.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.fz-20 {
  font-size: 20px;
}
.width20 {
  width: 20%;
}
.p-19 {
  padding: 19px;
}
.p-t-19 {
  padding-top: 19px;
}
.p-r-19 {
  padding-right: 19px;
}
.p-b-19 {
  padding-bottom: 19px;
}
.p-l-19 {
  padding-left: 19px;
}
.m-19 {
  margin: 19px;
}
.m-t-19 {
  margin-top: 19px;
}
.m-r-19 {
  margin-right: 19px;
}
.m-b-19 {
  margin-bottom: 19px;
}
.m-l-19 {
  margin-left: 19px;
}
.fz-19 {
  font-size: 19px;
}
.width19 {
  width: 19%;
}
.p-18 {
  padding: 18px;
}
.p-t-18 {
  padding-top: 18px;
}
.p-r-18 {
  padding-right: 18px;
}
.p-b-18 {
  padding-bottom: 18px;
}
.p-l-18 {
  padding-left: 18px;
}
.m-18 {
  margin: 18px;
}
.m-t-18 {
  margin-top: 18px;
}
.m-r-18 {
  margin-right: 18px;
}
.m-b-18 {
  margin-bottom: 18px;
}
.m-l-18 {
  margin-left: 18px;
}
.fz-18 {
  font-size: 18px;
}
.width18 {
  width: 18%;
}
.p-17 {
  padding: 17px;
}
.p-t-17 {
  padding-top: 17px;
}
.p-r-17 {
  padding-right: 17px;
}
.p-b-17 {
  padding-bottom: 17px;
}
.p-l-17 {
  padding-left: 17px;
}
.m-17 {
  margin: 17px;
}
.m-t-17 {
  margin-top: 17px;
}
.m-r-17 {
  margin-right: 17px;
}
.m-b-17 {
  margin-bottom: 17px;
}
.m-l-17 {
  margin-left: 17px;
}
.fz-17 {
  font-size: 17px;
}
.width17 {
  width: 17%;
}
.p-16 {
  padding: 16px;
}
.p-t-16 {
  padding-top: 16px;
}
.p-r-16 {
  padding-right: 16px;
}
.p-b-16 {
  padding-bottom: 16px;
}
.p-l-16 {
  padding-left: 16px;
}
.m-16 {
  margin: 16px;
}
.m-t-16 {
  margin-top: 16px;
}
.m-r-16 {
  margin-right: 16px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-l-16 {
  margin-left: 16px;
}
.fz-16 {
  font-size: 16px;
}
.width16 {
  width: 16%;
}
.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.fz-15 {
  font-size: 15px;
}
.width15 {
  width: 15%;
}
.p-14 {
  padding: 14px;
}
.p-t-14 {
  padding-top: 14px;
}
.p-r-14 {
  padding-right: 14px;
}
.p-b-14 {
  padding-bottom: 14px;
}
.p-l-14 {
  padding-left: 14px;
}
.m-14 {
  margin: 14px;
}
.m-t-14 {
  margin-top: 14px;
}
.m-r-14 {
  margin-right: 14px;
}
.m-b-14 {
  margin-bottom: 14px;
}
.m-l-14 {
  margin-left: 14px;
}
.fz-14 {
  font-size: 14px;
}
.width14 {
  width: 14%;
}
.p-13 {
  padding: 13px;
}
.p-t-13 {
  padding-top: 13px;
}
.p-r-13 {
  padding-right: 13px;
}
.p-b-13 {
  padding-bottom: 13px;
}
.p-l-13 {
  padding-left: 13px;
}
.m-13 {
  margin: 13px;
}
.m-t-13 {
  margin-top: 13px;
}
.m-r-13 {
  margin-right: 13px;
}
.m-b-13 {
  margin-bottom: 13px;
}
.m-l-13 {
  margin-left: 13px;
}
.fz-13 {
  font-size: 13px;
}
.width13 {
  width: 13%;
}
.p-12 {
  padding: 12px;
}
.p-t-12 {
  padding-top: 12px;
}
.p-r-12 {
  padding-right: 12px;
}
.p-b-12 {
  padding-bottom: 12px;
}
.p-l-12 {
  padding-left: 12px;
}
.m-12 {
  margin: 12px;
}
.m-t-12 {
  margin-top: 12px;
}
.m-r-12 {
  margin-right: 12px;
}
.m-b-12 {
  margin-bottom: 12px;
}
.m-l-12 {
  margin-left: 12px;
}
.fz-12 {
  font-size: 12px;
}
.width12 {
  width: 12%;
}
.p-11 {
  padding: 11px;
}
.p-t-11 {
  padding-top: 11px;
}
.p-r-11 {
  padding-right: 11px;
}
.p-b-11 {
  padding-bottom: 11px;
}
.p-l-11 {
  padding-left: 11px;
}
.m-11 {
  margin: 11px;
}
.m-t-11 {
  margin-top: 11px;
}
.m-r-11 {
  margin-right: 11px;
}
.m-b-11 {
  margin-bottom: 11px;
}
.m-l-11 {
  margin-left: 11px;
}
.fz-11 {
  font-size: 11px;
}
.width11 {
  width: 11%;
}
.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.fz-10 {
  font-size: 10px;
}
.width10 {
  width: 10%;
}
.p-9 {
  padding: 9px;
}
.p-t-9 {
  padding-top: 9px;
}
.p-r-9 {
  padding-right: 9px;
}
.p-b-9 {
  padding-bottom: 9px;
}
.p-l-9 {
  padding-left: 9px;
}
.m-9 {
  margin: 9px;
}
.m-t-9 {
  margin-top: 9px;
}
.m-r-9 {
  margin-right: 9px;
}
.m-b-9 {
  margin-bottom: 9px;
}
.m-l-9 {
  margin-left: 9px;
}
.fz-9 {
  font-size: 9px;
}
.width9 {
  width: 9%;
}
.p-8 {
  padding: 8px;
}
.p-t-8 {
  padding-top: 8px;
}
.p-r-8 {
  padding-right: 8px;
}
.p-b-8 {
  padding-bottom: 8px;
}
.p-l-8 {
  padding-left: 8px;
}
.m-8 {
  margin: 8px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-r-8 {
  margin-right: 8px;
}
.m-b-8 {
  margin-bottom: 8px;
}
.m-l-8 {
  margin-left: 8px;
}
.fz-8 {
  font-size: 8px;
}
.width8 {
  width: 8%;
}
.p-7 {
  padding: 7px;
}
.p-t-7 {
  padding-top: 7px;
}
.p-r-7 {
  padding-right: 7px;
}
.p-b-7 {
  padding-bottom: 7px;
}
.p-l-7 {
  padding-left: 7px;
}
.m-7 {
  margin: 7px;
}
.m-t-7 {
  margin-top: 7px;
}
.m-r-7 {
  margin-right: 7px;
}
.m-b-7 {
  margin-bottom: 7px;
}
.m-l-7 {
  margin-left: 7px;
}
.fz-7 {
  font-size: 7px;
}
.width7 {
  width: 7%;
}
.p-6 {
  padding: 6px;
}
.p-t-6 {
  padding-top: 6px;
}
.p-r-6 {
  padding-right: 6px;
}
.p-b-6 {
  padding-bottom: 6px;
}
.p-l-6 {
  padding-left: 6px;
}
.m-6 {
  margin: 6px;
}
.m-t-6 {
  margin-top: 6px;
}
.m-r-6 {
  margin-right: 6px;
}
.m-b-6 {
  margin-bottom: 6px;
}
.m-l-6 {
  margin-left: 6px;
}
.fz-6 {
  font-size: 6px;
}
.width6 {
  width: 6%;
}
.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.fz-5 {
  font-size: 5px;
}
.width5 {
  width: 5%;
}
.p-4 {
  padding: 4px;
}
.p-t-4 {
  padding-top: 4px;
}
.p-r-4 {
  padding-right: 4px;
}
.p-b-4 {
  padding-bottom: 4px;
}
.p-l-4 {
  padding-left: 4px;
}
.m-4 {
  margin: 4px;
}
.m-t-4 {
  margin-top: 4px;
}
.m-r-4 {
  margin-right: 4px;
}
.m-b-4 {
  margin-bottom: 4px;
}
.m-l-4 {
  margin-left: 4px;
}
.fz-4 {
  font-size: 4px;
}
.width4 {
  width: 4%;
}
.p-3 {
  padding: 3px;
}
.p-t-3 {
  padding-top: 3px;
}
.p-r-3 {
  padding-right: 3px;
}
.p-b-3 {
  padding-bottom: 3px;
}
.p-l-3 {
  padding-left: 3px;
}
.m-3 {
  margin: 3px;
}
.m-t-3 {
  margin-top: 3px;
}
.m-r-3 {
  margin-right: 3px;
}
.m-b-3 {
  margin-bottom: 3px;
}
.m-l-3 {
  margin-left: 3px;
}
.fz-3 {
  font-size: 3px;
}
.width3 {
  width: 3%;
}
.p-2 {
  padding: 2px;
}
.p-t-2 {
  padding-top: 2px;
}
.p-r-2 {
  padding-right: 2px;
}
.p-b-2 {
  padding-bottom: 2px;
}
.p-l-2 {
  padding-left: 2px;
}
.m-2 {
  margin: 2px;
}
.m-t-2 {
  margin-top: 2px;
}
.m-r-2 {
  margin-right: 2px;
}
.m-b-2 {
  margin-bottom: 2px;
}
.m-l-2 {
  margin-left: 2px;
}
.fz-2 {
  font-size: 2px;
}
.width2 {
  width: 2%;
}
.p-1 {
  padding: 1px;
}
.p-t-1 {
  padding-top: 1px;
}
.p-r-1 {
  padding-right: 1px;
}
.p-b-1 {
  padding-bottom: 1px;
}
.p-l-1 {
  padding-left: 1px;
}
.m-1 {
  margin: 1px;
}
.m-t-1 {
  margin-top: 1px;
}
.m-r-1 {
  margin-right: 1px;
}
.m-b-1 {
  margin-bottom: 1px;
}
.m-l-1 {
  margin-left: 1px;
}
.fz-1 {
  font-size: 1px;
}
.width1 {
  width: 1%;
}
.label-title {
  display: block;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: var(--highlight-4);
}
.monad {
  width: 8px;
  height: 11px;
  display: inline-block;
  margin-right: 5px;
}
.ape-coin {
  width: 11px!important;
  height: 11px!important;
  display: inline-block;
  margin-right: 5px;
}
.filter-title {
  padding: 7px 8px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
}
.filter-title:hover {
  background: #282C38;
}
.page_title {
  font-family: Impact;
  font-size: 24px;
  color: #fff;
  white-space: nowrap;
}
.no-wrap {
  white-space: nowrap;
}
.normal_title_color {
  color: var(--gray-3);
}
.question-icon {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}
.list {
  padding: 10px 0px;
}
.list .list-item {
  height: 30px;
  line-height: 30px;
  min-width: 180px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 18px;
}
.list .list-item:hover {
  background: rgba(229, 230, 237, 0.08);
}
.list .active-item {
  background: rgba(229, 230, 237, 0.08);
}
.letter {
  letter-spacing: 1px;
}
.hover-btn {
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.hover-btn:hover {
  background: #282C38;
}
.active-hover-btn .iconfont {
  color: #FA2256 !important;
}
.font_12 {
  font-size: 12px;
}
.font_13 {
  font-size: 13px;
}
.font_14 {
  font-size: 14px;
}
.font_15 {
  font-size: 15px;
}
.font_16 {
  font-size: 16px;
}
.font_17 {
  font-size: 17px;
}
.font_18 {
  font-size: 18px;
}
.font_19 {
  font-size: 19px;
}
.font_20 {
  font-size: 20px;
}
@-webkit-keyframes animation-rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes animation-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.global-loading {
  display: inline-block;
  border-top: 2px solid currentcolor;
  border-right: 2px solid currentcolor;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: 0.45s linear 0s infinite normal none running animation-rotate;
  width: 16px;
  height: 16px;
  --spinner-size: 1rem;
  margin-top: -3px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.mobile-content-scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.mobile-content-margin-l-10 {
  margin-left: 10px;
}
.no-margin {
  margin: 0;
}
.live-view-animation {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
}
@keyframes live-view-animation-before {
  from {
    width: 100%;
    height: 100%;
    margin-left: 0%;
    margin-top: 0%;
    opacity: 1;
  }
  to {
    width: 300%;
    height: 300%;
    opacity: 0;
  }
}
@keyframes live-view-animation-after {
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.live-view-animation::before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 12px;
  background-color: #4cb55d;
  animation: 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal none running live-view-animation-before;
  -webkit-animation: 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s infinite normal none running live-view-animation-before;
}
.live-view-animation::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #4cb55d;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px;
  animation: 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite normal none running live-view-animation-after;
  -webkit-animation: 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite normal none running live-view-animation-after;
}
.public-img-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  color: #757B8C !important;
}
.page-container-wrapper {
  flex: 1;
  flex-direction: column;
  display: flex;
}
.icon-a-Arrow-Down,
.icon-a-Arrow-Down2 {
  transition: all 0.3s ease;
}
.icon-a-Arrow-Down.active,
.icon-a-Arrow-Down2.active {
  transform: rotate(180deg);
}
.search-menu-absolute-width {
  max-width: 100%;
}
.logo-img-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.logo-img-container img {
  width: 100%!important;
  height: 100% !important;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
}
.logo-img-container .active {
  display: none;
}
.logo-img-container:hover .normal,
.logo-img-container.active .normal {
  display: none;
}
.logo-img-container:hover .active,
.logo-img-container.active .active {
  display: inline-block;
}
#loading-img {
  display: block;
  width: 24px;
  height: 24px;
  margin: auto;
  animation: loading 0.5s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
