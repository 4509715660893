// 一屏展示
.app-one-screen-display{
  height: 100vh;
  ::v-deep .v-application--wrap{
    overflow: hidden;
  }
}
// .global-snackbar-alert{
//   // background: #A45CFF !important;
//   padding-left: 20px !important;
//   min-width: 300px !important;
//   font-weight: bold !important;
// }
.base-layout{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}